<template>
	<v-layout>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-chip
					v-show="!minimized"
					:class="{
						pointer: clickable
					}"
					:color="counterBackgroundColor"
					:disabled="!selected"
					:light="!dark"
					:outline="!dark"
					:selected="selected"
					small
					:text-color="counterTextColor"
					v-on="on"
					@click="onClick"
				>
					<v-avatar :color="counterBackgroundAccentColor">
						<v-progress-circular v-if="loading" class="mx-2" indeterminate />
						<span v-else :class="`caption ${counterTextColor}`" v-text="count ? count : 0" />
					</v-avatar>
					<span :class="dark ? counterTextColor : undefined" v-text="label" />
				</v-chip>
				<v-avatar
					v-show="minimized"
					:color="counterBackgroundAccentColor"
					:class="{
						pointer: clickable
					}"
					size="1.25em"
					@click="onClick"
					v-on="on"
				>
					<v-progress-circular v-if="loading" class="mx-2" indeterminate />
					<span v-else :class="`caption ${counterTextColor}`" v-text="count ? count : 0" />
				</v-avatar>
			</template>
			<span v-text="label" />
		</v-tooltip>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'LabelledCounter',
	props: {
		clickable: {
			required: false,
			type: Boolean,
			default: false
		},
		color: {
			required: false,
			type: String,
			default: ''
		},
		count: {
			required: true,
			type: Number
		},
		label: {
			required: false,
			type: String,
			default: ''
		},
		loading: {
			required: false,
			type: Boolean,
			default: false
		},
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			selected: false
		}
	},
	computed: {
		...mapState({
			dark: state => state.user.darkMode
		}),
		counterBackgroundAccentColor: function () {
			return this.count > 0 ? this.color : 'grey'
		},
		counterBackgroundColor: function () {
			return this.count > 0 ? this.color : 'grey'
		},
		counterTextColor: function () {
			return this.$getContrastedTextColor(this.counterBackgroundAccentColor)
		}
	},
	methods: {
		onClick: function () {
			if (this.clickable) {
				this.selected = true
				this.$emit('click')
			}
		}
	}
}
</script>
